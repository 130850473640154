export const environment = {
  production: true,
  appId: 'com.payquad',
  apiBaseUrl: 'https://payquad.sdhub.ca/api/',
  apiBase: 'https://payquad.sdhub.ca/',
  managerApp: 'https://payquad.sdhub.ca/',
  apiVersion: 'v2',
  appName: 'tenant_web',
  useCsrfToken: true,

  plaidEnv: 'sandbox',
  plaidClientName: 'Payquad Account Link',
  plaidPublicKey: 'ac18c7510a6547a89c616549f75199',

  openWeatherApiKey: 'a191bfa5cda4556023437bcc8c96dc50',
};
